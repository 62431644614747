@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply text-primary;
  @apply bg-primary;
  font-family: 'SF Pro Rounded', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: 400;
  src: url('https://cdn.bloomapp.com/fonts/SF-Pro-Rounded-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: 500;
  src: url('https://cdn.bloomapp.com/fonts/SF-Pro-Rounded-Medium.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: 600;
  src: url('https://cdn.bloomapp.com/fonts/SF-Pro-Rounded-Semibold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: 700;
  src: url('https://cdn.bloomapp.com/fonts/SF-Pro-Rounded-Bold.woff2') format('woff2');
  font-display: swap;
}

.absolute-vertical-center {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

h1 {
  @apply text-title-0;
}

h2 {
  @apply text-title-1;
}

h3 {
  @apply text-title-2;
}

h4 {
  @apply text-title-3;
}

h5 {
  @apply text-headline;
}

h6 {
  @apply text-subhead;
}

p {
  @apply text-headline;
}

hr {
  @apply border-primary;
  height: 0.5;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}